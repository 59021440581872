import { createTheme } from '@mui/material/styles';

const theme = createTheme({
   palette: {
      primary: {
         main: '#3366FF',
         light: '#3794F8',
         dark: '#2250AC',
      },
      secondary: {
         main: '#ffffff',
      },
      light: {
         main: '#F5F6FA',
      },
      error: {
         main: '#D14343',
      },
      warning: {
         main: '#FFB020',
         contrastText: '#A66A00',
      },
      info: {
         main: '#3366FF',
         contrastText: '#FFFFFF',
      },
      success: {
         main: '#52BD94',
         contrastText: '#FFFFFF',
      },
      text: {
         primary: '#101840',
         secondary: '#696F8C',
      },
   },
   shape: {
      borderRadius: 8,
   },
   typography: {
      fontFamily: 'Inter, sans-serif',
      h1: {
         fontSize: '32px',
         fontWeight: 600,
         lineHeight: '40px',
      },
      h2: {
         fontSize: '24px',
         fontWeight: 500,
         lineHeight: '32px',
      },
      h3: {
         fontSize: '20px',
         fontWeight: 500,
         lineHeight: '28px',
      },
      h4: {
         fontSize: '18px',
         fontWeight: 400,
         lineHeight: '24px',
      },
      h5: {
         fontSize: '16px',
         fontWeight: 400,
         lineHeight: '24px',
      },
      h6: {
         fontSize: '14px',
         fontWeight: 600,
         lineHeight: '20px',
      },
      h7: {
         fontSize: '12px',
         fontWeight: 500,
         lineHeight: '16px',
      },
   },

   spacing: 2,

   breakpoints: {
      values: {
         xxs: 0,
         xs: 300,
         sm: 600,
         md: 960,
         lg: 1280,
         xl: 1920,
      },
   },
});

export default theme;
