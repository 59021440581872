export const SG_EVENT_NAMES = {
   // Segment events
   PAGE_VIEW: 'Page View',
   PAGE_VIEWED: 'Page Viewed',
   USER_SIGN_UP: 'Sign Up',
   USER_SIGN_UP_FAILED: 'Sign Up Failed',
   USER_SIGN_IN: 'Sign In',
   USER_SIGN_IN_FAILED: 'Sign In Failed',
   USER_SIGN_OUT: 'Sign Out',
   USER_SIGN_OUT_FAILED: 'Sign Out Failed',
   USER_FORGOT_PASSWORD: 'Forgot Password',
   USER_RESET_PASSWORD: 'Reset Password',
   USER_DOWNLOAD_ITEM: 'Download Item',
   USER_DOWNLOAD_ITEM_FAILED: 'Download Item Failed',
   USER_ACCESS_PLUGIN: 'Access Plugin',
   USER_USE_PLUGIN: 'Use Plugin',
   USER_CREATE_BRAND: 'Create Brand', // CREATE NEW BRAND FROM DASHBOARD
   USER_SHARE_BRAND: 'Share Brand', // SHARE BRAND FROM DASHBOARD
   USER_USE_BRAND: 'Use Brand', // DOWNLOAD ANY BRAND ITEM FROM DASHBOARD OR PLUGIN
   USER_UPLOAD_LIBRARY_FILE: 'Upload Library File',
   USER_CREATE_LIBRARY_FOLDER: 'Create Library Folder',
   USER_SHARE_LIBRARY: 'Share Library',
   USER_USE_LIBRARY: 'Use Library',
   USER_SUBSCRIBE: 'Subscribe',
   USER_UPGRADE_PLAN: 'Upgrade Plan',
   ERROR: 'Error',
};
